import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login.vue';
import DocList from '@/views/document/DocList';
import Document from '@/views/document/Document';
import store from "@/store";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/', component: DocList, meta: {title: 'Список документов'}},
        {path: '/create', component: Document, meta: {title: 'Создание документа'}, props: {creating: true}},
        {path: '/:id', component: Document, meta: {title: 'Редактирование документа'}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    if (store.getters.isAuthorized && '/login' === to.path) {
        return next('/');
    }
    if (!store.getters.isAuthorized && '/login' !== to.path) {
        return next('/login');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/' !== to.path) {
        return next('/');
    }
    if (!store.getters.isAdmin && '/create' === to.path) {
        return next('/');
    }
    return next();
});

export default router;
