import {AXIOS} from './backend-config';

export default {
    getDocumentIndexList(offset, limit) {
        return AXIOS.get('/document/', {
            params: {
                offset,
                limit
            }
        })
    },
    getDocumentList(offset, limit, isDeleted) {
        return AXIOS.get('/document/info', {
            params: {
                offset,
                limit,
                isDeleted
            }
        })
    },
    getSingle(id) {
        return AXIOS.get(`/document/${id}`);
    },
    createDocument(document) {
        return AXIOS.post('/document/', document);
    },
    deleteDocument(id) {
        console.log('deleting', id)
        return AXIOS.delete(`/document/${id}`);
    },
    updateDocument(document) {
        return AXIOS.put(`/document/${document.id}`, document);
    },
    uploadFile(attachmentInfo, fileBlob) {
        let formData = new FormData();
        for (const [key, value] of Object.entries(attachmentInfo)) {
            formData.append(key, value);
        }
        formData.append('file', fileBlob);
        return AXIOS({
            url: '/files/upload',
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
            }
        })
    },
    deleteAttachment(attId, filePath) {
        return AXIOS.delete(`/files/download/${attId}_${filePath}`);
    },
    getBackendVersion() {
        return AXIOS.get('application-info/versions')
    }
}
